import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { IonLoading, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import Notification from "./components/Notification";
import { Dispatch, RootState } from "./store";

import { AxiosError } from "axios";
import { AdapterListItem, Subscription } from "./model";
import NotificationHandler from "./NotificationHandler";
import {
  DeviceRoutes,
  Home,
  InternetRoutes,
  LoginRoutes,
  ProfileRoutes,
  SecuritySettings,
  SettingsRoutes,
  AccountRoutes,
  StreamingRelocationScreen,
  OnboardingRoutes,
  AdminRoutes,
} from "./pages";
import axios from "axios";
import AppUrlListener from "./helper/AppUrlListener";
import { tryCatch } from "./utils";
import { getAdapterList } from "./api/adapter";
import httpErrorInterceptor from "./api/httpErrorInterceptor";
import api from "api";
import Banner from "components/Banner";
import NewModal from "components/NewModal";
import { App } from "@capacitor/app";
import i18n from "utils/i18n";
import SubscribeContainer from "./pages/account/Subscribe";
import UpgradeContainer from "./pages/account/Upgrade";
import RedemptionContainer from "./pages/account/Redemption";

interface Props {
  TestRoute?: React.FC<unknown>;
}

const RenderLoggedInRoutes = ({
  adapter,
  subscription,
  subscriptionReady,
  loaded,
  admin,
}: {
  adapter: AdapterListItem | undefined;
  subscription: Subscription | undefined;
  subscriptionReady: boolean;
  loaded: boolean;
  admin: boolean;
}) => {
  if (admin) {
    return (
      <Switch>
        <Route path="/admin">
          <AdminRoutes />
        </Route>
        <Redirect to="/admin" />
      </Switch>
    );
  } else if (adapter) {
    return (
      <Switch>
        <Route path="/home" exact>
          <Home adapterId={adapter.id} />
        </Route>
        <Route path="/security" exact>
          {subscription ? (
            subscription.product_permissions["VPN"] &&
            subscription.product_permissions["VPN"].toLowerCase() === "true" ? (
              <SecuritySettings
                adapterId={adapter.id}
                pheroVpn={
                  subscription.product_permissions["PHERO_VPN"] &&
                  subscription.product_permissions[
                    "PHERO_VPN"
                  ].toLowerCase() === "true"
                    ? true
                    : false
                }
              />
            ) : (
              <UpgradeContainer reason="VPN" />
            )
          ) : (
            <SubscribeContainer reason="VPN" />
          )}
        </Route>
        <Route path="/streaming" exact>
          {subscription ? (
            subscription.product_permissions["STREAMING_RELOCATION"] &&
            subscription.product_permissions[
              "STREAMING_RELOCATION"
            ].toLowerCase() === "true" ? (
              <StreamingRelocationScreen />
            ) : (
              <UpgradeContainer reason="STREAMING_RELOCATION" />
            )
          ) : (
            <SubscribeContainer reason="STREAMING_RELOCATION" />
          )}
        </Route>
        <Route path="/internet">
          {subscription ? (
            subscription.product_permissions["DATA"] &&
            subscription.product_permissions["DATA"].toLowerCase() ===
              "true" ? (
              <InternetRoutes adapter={adapter} />
            ) : (
              <UpgradeContainer reason="DATA" />
            )
          ) : (
            <SubscribeContainer reason="DATA" />
          )}
        </Route>
        <Route path="/device">
          {subscription ? (
            subscription.product_permissions["DEVICES"] &&
            subscription.product_permissions["DEVICES"].toLowerCase() ===
              "true" ? (
              <DeviceRoutes adapter={adapter} />
            ) : (
              <UpgradeContainer reason="DEVICES" />
            )
          ) : (
            <SubscribeContainer reason="DEVICES" />
          )}
        </Route>
        <Route path="/profile">
          {subscription ? (
            subscription.product_permissions["PROFILES"] &&
            subscription.product_permissions["PROFILES"].toLowerCase() ===
              "true" ? (
              <ProfileRoutes adapter={adapter} />
            ) : (
              <UpgradeContainer reason="PROFILES" />
            )
          ) : (
            <SubscribeContainer reason="PROFILES" />
          )}
        </Route>
        <Route path="/settings">
          <SettingsRoutes subscription={subscription} />
        </Route>
        <Route path="/account">
          <AccountRoutes subscription={subscription} />
        </Route>
        <Route path="/onboarding">
          <OnboardingRoutes adapter={adapter} />
        </Route>
        <Route path="/redemption">
          <RedemptionContainer />
        </Route>
        <Redirect to="/home" />
      </Switch>
    );
  } else if (!adapter && loaded) {
    return (
      <Switch>
        <Route path="/onboarding" component={OnboardingRoutes} />
        <Redirect to="/onboarding" />
      </Switch>
    );
  }
  return null;
};
const AppComponent: React.FC<Props> = () => {
  // NOTE: Remove once subscription endpoints are returning valid responses to restrict access
  const dispatch = useDispatch<Dispatch>();
  const removeUser = dispatch.authentication.removeUserToken;
  const setAdapterList = dispatch.adapter.setAdapterList;
  const setUser = dispatch.authentication.updateUserToken;
  const setSubscriptions = dispatch.adapter.setSubscriptions;
  const { adapter, subscription, user } = useSelector((state: RootState) => {
    const { adapter, subscriptions } = state.adapter;
    const subscription = adapter
      ? subscriptions.find(
          (sub: Subscription) => sub.adapter_id && sub.adapter_id === adapter.id
        )
      : undefined;
    return {
      adapter,
      subscription,
      user: state.authentication.user,
    };
  });
  const [subscriptionReady] = useState(false);
  const adapterId = useMemo(() => adapter?.id, [adapter]);
  const [devicesLoaded, setDevicesLoaded] = useState(false);
  const [adapterLoaded, setAdapterLoaded] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const isFreshRender = useMemo(
    () => new URLSearchParams(location.search).get("fresh"),
    [location.search]
  );
  const selector = (state: RootState) => ({
    toast: state.ui.toast,
  });
  const { toast } = useSelector<RootState, ReturnType<typeof selector>>(
    selector
  );
  useEffect(() => {
    if (adapterId) {
      dispatch.authentication.followAppState(adapterId);
    }
  }, [dispatch.authentication, adapterId]);
  useEffect(() => {
    axios.interceptors.response.use(undefined, (error: AxiosError) =>
      httpErrorInterceptor(error, {
        isLoggedIn: !!user,
        logout: dispatch.authentication.removeUserToken,
        notify: (description: string) =>
          dispatch.ui.setToast({
            type: "warning",
            description,
          }),
        mergeUserToken: dispatch.authentication.mergeUserToken,
      })
    );
  }, [
    dispatch.authentication.mergeUserToken,
    dispatch.authentication.removeUserToken,
    dispatch.ui,
    user,
  ]);
  useEffect(() => {
    if (user) {
      api.auth
        .renewToken()
        .then(({ data }) => setUser({ ...user, ...data }))
        .catch(() => removeUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    async function loadDeviceList() {
      if (user && adapterId && !user.admin) {
        setDevicesLoaded(false);
        await dispatch.device.getDevices(adapterId);
        setDevicesLoaded(true);
      } else {
        setDevicesLoaded(true);
      }
    }
    async function loadAdaptersAndSubscriptions() {
      if (user && !user.admin) {
        // this is necessary to properly route home on second login
        setAdapterLoaded(false);
        //TODO move to redux
        const adapterListResult = await tryCatch(getAdapterList());
        if (adapterListResult.error) {
          setAdapterLoaded(true);
          dispatch.authentication.removeUserToken();
          dispatch.ui.setToast({
            type: "warning",
            description: i18n.t("generic:errors.sessionExpiredMessage"),
          });
        } else {
          const a = adapter;
          setAdapterList(adapterListResult.result.data);
          const subscriptionsResult = await tryCatch(
            api.subscription.getSubscriptionList()
          );
          setAdapterLoaded(true);
          if (!a && location.pathname.includes("onboarding")) {
            // fix for deep link login
            history.push("/home");
          }
          if (subscriptionsResult.error) {
            console.warn(subscriptionsResult.error.message);
            setSubscriptions([]);
          } else {
            setSubscriptions(subscriptionsResult.result.data.subscriptions);
          }
        }
      } else if (user?.admin) {
        setAdapterLoaded(true);
      } else {
        setAdapterLoaded(true);
      }
    }

    async function LoadEverything() {
      await loadAdaptersAndSubscriptions();
      await loadDeviceList();
      if (user && !user.admin && adapterId && !originalImpersonatorUser) {
        dispatch.adapter.pollForAdapter(adapterId);
        dispatch.authentication.renewTokenContinuously();
      } else {
        dispatch.adapter.stopPollingForAdapter();
      }
    }
    LoadEverything();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adapterId, user?.email, isFreshRender]);
  const Notif = user && !user.admin ? NotificationHandler : React.Fragment;
  const loaded = useMemo(() => devicesLoaded && adapterLoaded, [
    adapterLoaded,
    devicesLoaded,
  ]);

  const isAdapterOffline = useMemo<boolean>(
    () => !adapter?.services.online.state,
    [adapter]
  );

  const [
    isCloseConfirmationModalOpen,
    setIsCloseConfirmationModalOpen,
  ] = useState(false);
  const closeModal = () => setIsCloseConfirmationModalOpen(false);
  const closeApp = () => {
    App.exitApp();
  };
  const { notifications, isModalOpen, originalImpersonatorUser } = useSelector(
    (state: RootState) => ({
      notifications: state.notifications,
      isModalOpen: state.ui.isModalOpen,
      originalImpersonatorUser: state.authentication.originalImpersonatorUser,
    })
  );
  const dismiss = dispatch.notifications.dismissNotification;
  useEffect(() => {
    const backButtonListener = (ev: any) => {
      if (isModalOpen) {
        document.dispatchEvent(new Event("close-modal"));
      } else if (
        history.location.pathname.includes("home") ||
        history.length === 0
      ) {
        setIsCloseConfirmationModalOpen(true);
      } else {
        history.goBack();
      }
    };
    document.addEventListener("ionBackButton", backButtonListener);
    const cleanup = () => {
      // @ts-ignore
      document.removeEventListener("ionBackButton", backButtonListener, {});
    };
    return cleanup;
  }, [history, isModalOpen, setIsCloseConfirmationModalOpen]);

  return (
    <>
      {notifications.length > 0 ? (
        <Notification
          type="info"
          description={notifications[0].message!}
          title="Notification"
          isDisplayed
          hide={() => {
            dismiss({ ...notifications[0], onPress: undefined });
          }}
        />
      ) : null}
      <Notification
        {...toast}
        isDisplayed={!!toast}
        hide={() => dispatch.ui._setToast(undefined)}
      />
      <AppUrlListener />
      <NewModal
        type="warning"
        open={isCloseConfirmationModalOpen}
        onClose={closeModal}
        onOk={closeApp}
        title="Close app"
        description={`Are you sure you would like to close the app?`}
        okText="Yes"
        cancelText="Cancel"
      />
      <AppUrlListener />

      {user ? (
        <Notif>
          {loaded ? null : <IonLoading isOpen message={"Please wait..."} />}
          <IonSplitPane contentId="main-logged-in">
            <IonRouterOutlet id="main-logged-in">
              <RenderLoggedInRoutes
                adapter={adapter}
                subscription={subscription}
                subscriptionReady={subscriptionReady}
                loaded={loaded}
                admin={!!user.admin}
              />
              {adapter && isAdapterOffline ? (
                <Banner text="The router is offline! No configuration possible" />
              ) : null}
              {adapter && adapter.fw_status?.fw?.os?.update ? (
                <Banner
                  text={
                    "The router is updating! (os - " +
                    adapter.fw_status?.fw?.os?.pct +
                    "%)"
                  }
                />
              ) : null}
              {adapter && adapter.fw_status?.fw?.recovery?.update ? (
                <Banner
                  text={
                    "The router is updating! (recovery - " +
                    adapter.fw_status?.fw?.recovery?.pct +
                    "%)"
                  }
                />
              ) : null}
            </IonRouterOutlet>
          </IonSplitPane>
        </Notif>
      ) : (
        <IonSplitPane contentId="main-logged-out">
          <IonRouterOutlet id="main-logged-out">
            <LoginRoutes />
          </IonRouterOutlet>
        </IonSplitPane>
      )}
    </>
  );
};

export default AppComponent;
